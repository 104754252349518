import {
  AnalyticsService,
  FirebaseAnalyticsService,
  FirebaseDatabaseManagerService,
  FirebaseUserManagerService,
  MessageMessagerService,
  MockAnalyticsService,
  RealPopupService,
  RecorderLoggingService,
  SentryCrashReportingService,
  ServiceContextType,
  initializeFirebase,
} from '@confer/lib'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = initializeFirebase(firebaseConfig)

export const loggingService = new RecorderLoggingService({
  logLevel: import.meta.env.PROD ? 'warn' : 'debug',
}) // loggingService constructed before passing to other services' constructors

let analyticsService: AnalyticsService
if (import.meta.env.PROD) {
  analyticsService = new FirebaseAnalyticsService(firebaseApp, loggingService)
} else {
  analyticsService = new MockAnalyticsService()
}

const userManagerServiceInstance = new FirebaseUserManagerService(
  firebaseApp,
  import.meta.env.VITE_USE_EMULATORS === 'true',
  loggingService,
  import.meta.env.VITE_AZURE_TENANT_ID,
)

const databaseManagerServiceInstance = new FirebaseDatabaseManagerService(
  firebaseApp,
  import.meta.env.VITE_USE_EMULATORS === 'true',
  loggingService,
)

const crashReportingService = new SentryCrashReportingService(
  loggingService,
  import.meta.env.VITE_USE_SENTRY === 'true'
    ? {
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: import.meta.env.VITE_SENTRY_RELEASE,
      }
    : undefined,
)

const messagerService = new MessageMessagerService()

const popupService = new RealPopupService()

export const services: ServiceContextType = {
  userService: userManagerServiceInstance,
  databaseService: databaseManagerServiceInstance,
  crashReportingService: crashReportingService,
  analyticsService: analyticsService,
  loggingService: loggingService,
  messagerService: messagerService,
  popupService: popupService,
}
